import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@/vuexy/utils/utils'
import { isNavGroupActive } from '@/vuexy/layouts/utils'

export default function useHorizontalNavMenuHeaderGroup(item: any) {
  const { route } = useRouter()
  // ------------------------------------------------
  // isOpen
  // ------------------------------------------------
  const isOpen = ref(false)

  const updateGroupOpen = (val: any) => {
    // eslint-disable-next-line no-use-before-define
    isOpen.value = val
  }

  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActive = ref(false)

  const updateIsActive = () => {
    isActive.value = isNavGroupActive(item.children)
  }

  watch(route, updateIsActive)

  return {
    isOpen,
    isActive,
    updateGroupOpen,
    updateIsActive,
  }
}
