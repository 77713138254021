import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@/vuexy/utils/utils'
import { isNavLinkActive, navLinkProps } from '@/vuexy/layouts/utils'

export default function useHorizontalNavMenuLink(item: any) {
  const { route } = useRouter()

  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  watch(route, updateIsActive)

  return {
    isActive,
    linkProps,
    updateIsActive,
  }
}
